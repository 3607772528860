import React, { useEffect } from 'react'
import './Menu.scss'

import pdfMenu from '../../assets/file/menu_2023.pdf'
import pdfDrinks from '../../assets/file/drinks.pdf'
import pdfWine from '../../assets/file/wine.pdf'
import MenuMobile from './MenuMobile/MenuMobile'
import bottomImage1 from '../../assets/NewYear.png'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Menu = () => {
  const openPdfMenu = () => {
    window.open(pdfMenu, '_blank');
  };
  const openPdfDrinks = () => {
    window.open(pdfDrinks, '_blank');
  };
  const openPdfWine = () => {
    window.open(pdfWine, '_blank');
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <div className='menu-desktop'>
        <div className='menu-section-1'>
          <img src={bottomImage1} className='menu-section-1__img' alt="bottomImage1" />
        </div>
        <div className='menu-section-2'>
          <button className='menu-section-2-button' onClick={openPdfMenu} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Download menu</button>
          <button className='menu-section-2-button' onClick={openPdfDrinks} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Drinks</button>
          <button className='menu-section-2-button' onClick={openPdfWine} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Wine</button>
        </div>
      </div>

      <MenuMobile />
    </>
  )
}

export default Menu
