import React from 'react'
import './MenuMobile.scss'

import bottomImage1 from '../../../assets/NewYear.png'

import pdfMenu from '../../../assets/file/menu_2023.pdf'
import pdfDrinks from '../../../assets/file/drinks.pdf'
import pdfWine from '../../../assets/file/wine.pdf'

const MenuMobile = () => {
  const openPdfMenu = () => {
    window.open(pdfMenu, '_blank');
  };
  const openPdfDrinks = () => {
    window.open(pdfDrinks, '_blank');
  };
  const openPdfWine = () => {
    window.open(pdfWine, '_blank');
  };

  return (
    <div className='menu-mobile'>
      <div className='menu-mobile-section-1'>
        <img className='menu-mobile-section-1__img' src={bottomImage1} alt="bottomImage1" />
      </div>
      <div className='menu-mobile-section-2'>
        <button className='menu-mobile-section-2-button' onClick={openPdfMenu} data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="500">Download menu</button>
        <button className='menu-mobile-section-2-button' onClick={openPdfDrinks} data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="500">Drinks</button>
        <button className='menu-mobile-section-2-button' onClick={openPdfWine} data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="500">Wine</button>
      </div>
    </div>
  )
}

export default MenuMobile
